import React, { useContext } from 'react'
import { SessionContext } from 'src/modules/Session/context'
import AnonReports from 'src/modules/MyReports/components/AnonReports/index.js'
import NoReportsFound from 'src/modules/MyReports/components/NoReportsFound/index.js'
import River from 'src/modules/River/components/River'
import { useSelector } from 'react-redux'

const MyReports = props => {
  const { authenticated, checked: sessionReady } = useContext(SessionContext)
  const { loading, reportsCount } = useSelector(state => state?.myReports)

  // Wait for session to be ready before evaluating
  if (!sessionReady || loading) return

  // If use is anonymous
  if (!authenticated) {
    return <AnonReports {...props} />
  }

  // User is logged in and does not have reports
  if (!reportsCount || reportsCount <= 0) {
    return <NoReportsFound {...props} />
  }
  // User is logged in and has reports
  return <River />
}

export default MyReports
