import { React } from 'react'
import PropTypes from 'prop-types'
import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import Typography from 'src/components/ui/Typography'
import { siteName } from 'src/utils/oneCodeBase'

const GradeController = ({ handleChange, styles, scoreArray, meta, input }) => {
  const handleGradeChange = (event, newValue) => {
    handleChange('grade', newValue)
  }
  return (
    <>
      <Typography
        className={styles.formLabel}
        style={{ flexGrow: 1, textAlign: 'justify', marginTop: '15px' }}
      >
        On a scale of 0 - 10, how likely are you to recommend FirstWord{' '}
        {siteName} to a friend or colleague?
      </Typography>

      <>
        <ToggleButtonGroup
          value={input.value}
          exclusive
          onChange={handleGradeChange}
          className={styles.btnContainer}
        >
          {scoreArray.map((val, key) => {
            return (
              <ToggleButton
                key={`grade-${val}`}
                value={val}
                className={`${styles.customButton} ${
                  input.value === val ? styles.customSelected : ''
                }`}
              >
                {val}
              </ToggleButton>
            )
          })}
        </ToggleButtonGroup>
        <div className={styles.btnTextContainer} style={{ display: 'flex' }}>
          <Typography className={styles.formLabel} style={{ width: '57px' }}>
            Not at all likely
          </Typography>
          <Typography
            className={styles.formLabel}
            align="right"
            style={{ width: '70px', direction: 'rtl' }}
          >
            Extremely likely
          </Typography>
        </div>
        {meta.error && meta.touched && (
          <Typography type="error" style={{ marginTop: '3px' }}>
            {meta.error}
          </Typography>
        )}
      </>
    </>
  )
}

GradeController.propTypes = {
  handleChange: PropTypes.func,
  styles: PropTypes.object,
  scoreArray: PropTypes.array,
  meta: PropTypes.object,
  input: PropTypes.object
}

export default GradeController
