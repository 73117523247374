import React, { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import styles from 'src/components/SingleStory/SingleStory.module.sass'
import Button from '@mui/material/Button'
import RequestAccessModal from 'src/modules/Paywall/Components/RequestModal.js'
import { getConfig } from 'src/modules/Config/selectors'
import { actions } from 'src/modules/SingleStory/reducer'
import { SessionContext } from 'src/modules/Session/context'
import usePiwik from 'src/hooks/usePiwik'

const NotifyUpdatesAndValid = ({ user, title, story }) => {
  const dispatch = useDispatch()
  const [notified, setNotified] = useState(null)
  const { handleTrackingWithArticleIdProp } = usePiwik()
  const notifyMeSettings =
    useSelector(getConfig('notify_me_settings'), shallowEqual) || {}
  const newsletterUuid =
    notifyMeSettings?.newsletter_notify_me_updates?.newsletter_uuid
  const productCode = story?.reportSettings?.productCode

  useEffect(() => {
    if (productCode) {
      const userIsNotified = user?.notifyMe?.find(
        item =>
          item?.preview_article_ids?.includes(productCode) &&
          item?.newsletter_uuid === newsletterUuid
      )
      setNotified(!!userIsNotified)
    }
  }, [productCode])

  const notifyUpdate = () => {
    setNotified(notified => !notified)
    dispatch(
      actions.requestSimpleNotifyMeSubscribe({
        newsletterUuid,
        articleId: productCode
      })
    )
    handleTrackingWithArticleIdProp(
      'article',
      'button-click',
      notified
        ? 'notify-me-of-future-updates'
        : 'cancel-notify-me-of-future-updates',
      story.id
    )
  }

  if (notified === null) return

  return (
    <Button
      className={
        notified ? styles.notifyActiveSideButton : styles.notifySideButton
      }
      variant="contained"
      onClick={notifyUpdate}
    >
      {notified ? '✓ You will be notified of future updates' : title}
    </Button>
  )
}

NotifyUpdatesAndValid.propTypes = {
  user: PropTypes.object,
  story: PropTypes.object,
  productCode: PropTypes.string,
  title: PropTypes.string
}

const NotifyUpdatesAndInvalid = ({ title, story }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const { handleTrackingWithArticleIdProp } = usePiwik()

  const handleOpenModal = () => {
    handleTrackingWithArticleIdProp(
      'pop-up',
      'view',
      'notify-me-anon-form',
      story.id
    )
    setModalOpen(true)
  }

  const handleCloseModal = () => {
    handleTrackingWithArticleIdProp('pop-up', 'click', 'close-window', story.id)
    setModalOpen(false)
  }

  const withSubmitTracking = () => {
    handleTrackingWithArticleIdProp(
      'pop-up',
      'button-click',
      'notify-me-anon-form-submit',
      story.id
    )
  }

  return (
    <>
      <button onClick={handleOpenModal} className={styles.notifySideButton}>
        {title}
      </button>
      <RequestAccessModal
        open={modalOpen}
        onCancel={handleCloseModal}
        withSubmitTracking={withSubmitTracking}
      />
    </>
  )
}

NotifyUpdatesAndInvalid.propTypes = {
  title: PropTypes.string,
  story: PropTypes.object
}

const NotifyUpdates = props => {
  const {
    authenticated,
    user,
    checked: sessionReady
  } = useContext(SessionContext)

  const title = props?.item?.title

  if (props?.story?.reportSettings?.upcoming || !sessionReady) return
  if (!authenticated)
    return <NotifyUpdatesAndInvalid {...props} user={user} title={title} />

  return <NotifyUpdatesAndValid {...props} user={user} title={title} />
}

NotifyUpdates.propTypes = {
  story: PropTypes.object,
  item: PropTypes.object
}

export default NotifyUpdates
