class FWReportsRepository {
  constructor(Fw5MLApi) {
    this.Fw5MLApi = Fw5MLApi
  }

  async getUserReports() {
    const req = await this.Fw5MLApi.fetch(
      `/profile/reports/available?limit=200`
    )
    const { data = [] } = req.data
    return data
  }

  async downloadReports(report) {
    try {
      const response = await this.Fw5MLApi.fetch(
        `/reports/download/${report}`,
        'GET',
        {},
        {
          responseType: 'blob'
        }
      )

      const file = new Blob([response.data], { type: 'application/pdf' })

      const fileURL = URL.createObjectURL(file)
      const a = document.createElement('a')
      a.href = fileURL

      console.log(response.headers)
      a.setAttribute('download', 'FirstWord Report.pdf')
      document.body.appendChild(a)
      a.click()
      a.remove()
    } catch (error) {
      console.error('Error downloading the report:', error)
      // Handle the error, e.g., show an error message to the user
    }
  }

  async getUserReportsCount() {
    // const req = await this.Fw5MLApi.fetch(`/reports/userReports/count`)
    // return req?.data?.reportCount || 0
    return 0
  }
}

export default FWReportsRepository
