import { rivers } from './contentRivers'
import { titleLabels } from './filterLabels'

export default {
  [rivers.MY_REPORTS]: () => ({
    title: 'My Reports',
    viewCount: false,
    // hideSubHeader: true,
    // showGroupByInterestTabs: true,
    showMedicalAbstractsToggle: false
  }),
  [rivers.RELATED_NEWS]: () => ({
    title: 'Related News',
    hideFilterSections: ['type'],
    viewCount: false,
    showMedicalAbstractsToggle: false
  }),
  [rivers.RELATED_ANALYSIS]: () => ({
    title: 'Related Analysis',
    hideFilterSections: ['type'],
    viewCount: false,
    showMedicalAbstractsToggle: false
  }),
  [rivers.ALL_REPORTS]: () => ({
    title: 'All Reports',
    hideFilterSections: ['type', 'publication_date'],
    // applyDefaultFilters: ['type'],
    // defaultFilters: allReports,
    viewCount: false,
    showMedicalAbstractsToggle: false
  }),
  [rivers.TAG]: ({ args = '' } = {}) => {
    const match = args.match(/^(\w+)\/(.*)$/)
    const [, , tag] = match === null ? [null, null, args] : match
    return {
      title: tag + ' Reports',
      excludeFilterValues: [tag],
      viewCount: false,
      includeRelatedTags: true,
      showMedicalAbstractsToggle: false
    }
  },
  [rivers.ALL_BY]: ({ args = '' } = {}) => {
    const match = args.match(/^(\w+)\/(.*)$/)
    const [, , value] = match === null ? [null, null, args] : match
    const titleMapped = titleLabels[value]
    return {
      // title: tag + ' Reports',
      title: 'All ' + titleMapped + ' Reports',
      // excludeFilterValues: [tag],
      viewCount: false,
      showMedicalAbstractsToggle: false
    }
  },
  [rivers.OTHER_BY]: ({ args = '' } = {}) => {
    const match = args.match(/^(\w+)\/(.*)$/)
    const [, , value] = match === null ? [null, null, args] : match
    const titleMapped = titleLabels[value]
    return {
      title: 'Other ' + titleMapped + ' Reports',
      viewCount: false,
      showMedicalAbstractsToggle: false
    }
  },
  [rivers.TYPE]: ({ args = '' } = {}) => {
    return {
      title: args,
      excludeFilterValues: [args],
      viewCount: false,
      showMedicalAbstractsToggle: false
    }
  },
  [rivers.UPCOMING_REPORTS]: () => ({
    title: 'Upcoming Reports',
    viewCount: false,
    showMedicalAbstractsToggle: false
  }),
  [rivers.SEARCH]: () => ({
    title: 'Search Results',
    viewCount: false,
    cancelLoadRiver: true,
    showMedicalAbstractsToggle: false,
    showFeaturedContent: false,
    getFeaturedContentTag: false
  }),
  [rivers.DEFAULT_RIVER]: () => ({
    title: 'search',
    viewCount: false,
    cancelLoadRiver: true,
    showMedicalAbstractsToggle: false
  })
}
