import React, { useContext } from 'react'
import styles from './Footer.module.sass'
import { useSelector } from 'react-redux'
import { getConfig } from 'src/modules/Config/selectors'
import Svg from 'src/components/Svg/index.js'
import Twitter from 'src/images/twitter.svg'
import LinkedIn from 'src/images/linkedin.svg'
import { Link } from 'react-router-dom'
import { getCurrentYear } from 'src/utils/index.js'
import { siteName } from 'src/utils/oneCodeBase.js'
import Typography from 'src/components/ui/Typography/index.js'
import { SessionContext } from 'src/modules/Session/context'
import { licenseTypes } from 'src/services/FW5ML/parsers/UserParser.js'
import ReactHtmlParser from 'react-html-parser'
import Logo from 'src/components/Logo/index.js'
import useLaunchDarkly from 'src/modules/LaunchDarkly/useLaunchDarkly'

function Footer() {
  const { userHasLicense = () => false } = useContext(SessionContext)
  const { getFeatureFlagMenuItem } = useLaunchDarkly()
  const menuConfig = useSelector(getConfig('menus'))
  const footer = menuConfig?.footer || []
  const { menu_items, sites, social_links } = footer

  return (
    <div className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.logos}>
          <Link className={styles.mainLogo} to="/">
            <Logo
              className={styles.logo}
              fill="#ffffff"
              width="220"
              height="19"
              alt={`FirstWord ${siteName}${
                userHasLicense(licenseTypes.LICENSE_PLUS) ? ' +' : ''
              }`}
            />
          </Link>
          <div className={styles.social}>
            {social_links?.linkedin && (
              <a
                className={styles.link}
                href={social_links?.linkedin}
                target="_blank"
                rel="noopener noreferrer"
                data-piwik-class="footer-social-linkedin"
              >
                <Svg
                  src={LinkedIn.toString()}
                  fill="#ffffff"
                  width="40"
                  height="40"
                  alt="LinkedIn"
                />
              </a>
            )}
            {social_links?.twitter && (
              <a
                className={styles.link}
                href={social_links?.twitter}
                target="_blank"
                rel="noopener noreferrer"
                data-piwik-class="footer-social-twitter"
              >
                <Svg
                  src={Twitter.toString()}
                  fill="transparent"
                  width="40"
                  height="40"
                  alt="Twitter"
                />
              </a>
            )}
          </div>
        </div>
        {sites && sites.length > 0 && (
          <ul className={styles.sitesContainer}>
            {sites.map((siteMenu, i) => {
              const site = getFeatureFlagMenuItem(siteMenu)
              return (
                site && (
                  <li key={`${site.label}${i + 1}`}>
                    {site.external ? (
                      <a className={styles.sites} href={site.to}>
                        <Typography className={styles.paragraph} paragraph>
                          {ReactHtmlParser(site.label)}
                        </Typography>
                      </a>
                    ) : (
                      <Link className={styles.sites} to={site.to}>
                        <Typography className={styles.paragraph} paragraph>
                          {ReactHtmlParser(site.label)}
                        </Typography>
                      </Link>
                    )}
                  </li>
                )
              )
            })}
          </ul>
        )}
        <div className={styles.legal}>
          <div>&copy; {getCurrentYear()} FirstWord. All rights reserved</div>
          <span>|</span>
          <div>1140 Avenue of the Americas, 14th Floor, New York, NY 10036</div>
        </div>

        {menu_items && menu_items.length > 0 && (
          <div className={styles.links}>
            {menu_items.map((item, i) => {
              return (
                <div key={`${item.label}${i + 1}`}>
                  <Link
                    to={item.to}
                    data-piwik-class="footer-link"
                    className={styles.menuItem}
                  >
                    {item.label}
                  </Link>
                  {i + 1 !== menu_items.length && <span>|</span>}
                </div>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}

export default Footer
