import asyncMeta from 'src/modules/AsyncFlags/metaCreator'
import { createReducer, createAction } from '@reduxjs/toolkit'

export const requestListReports = createAction(
  'ACCOUNT_LIST_REPORTS_REQUEST',
  null,
  asyncMeta
)
export const successListReports = createAction(
  'ACCOUNT_LIST_REPORTS__SUCCESS',
  null,
  asyncMeta
)
export const errorListReports = createAction(
  'ACCOUNT_LIST_REPORTS__ERROR',
  null,
  asyncMeta
)

export const requestReportsCount = createAction(
  'ACCOUNT_LIST_REPORTS_COUNT_REQUEST',
  null,
  asyncMeta
)
export const successReportsCount = createAction(
  'ACCOUNT_LIST_REPORTS_COUNT__SUCCESS',
  null,
  asyncMeta
)
export const errorReportsCount = createAction(
  'ACCOUNT_LIST_REPORTS_COUNT__ERROR',
  null,
  asyncMeta
)

export const actions = {
  requestListReports,
  successListReports,
  errorListReports,
  requestReportsCount,
  successReportsCount,
  errorReportsCount
}

const initialState = {
  reports: [],
  loading: false,
  error: '',
  reportsCount: null
}

const myReportsReducer = createReducer(initialState, {
  [requestListReports]: state => {
    state.loading = true
    state.reports = []
  },
  [successListReports]: (state, { payload: reports }) => {
    state.loading = false
    state.reports = reports
  },
  [errorListReports]: (state, { payload: error }) => {
    state.loading = false
    state.error = error
  },
  [requestReportsCount]: (state, { payload: count }) => {
    state.loading = true
    state.reportsCount = null
  },
  [successReportsCount]: (state, { payload: count }) => {
    state.loading = false
    state.reportsCount = count
  },
  [errorReportsCount]: (state, { payload: error }) => {
    state.loading = false
    state.error = error
  }
})

export default myReportsReducer
